@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./docsearch.css";
@import "./prism.css";

@import url("https://use.typekit.net/hiz5ldb.css");

body {
  font-family: "gill-sans-nova", Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
}

html {
  /* This is used for anchor link scrolling */
  /* height of sticky header - see https://css-tricks.com/fixed-headers-on-page-links-and-overlapping-content-oh-my/*/
  scroll-padding-top: 70px;
}

:root {
  --brand-purple: #5c6ac4;
}

p {
  line-height: 1.5;
}

#root {
  width: 100%;
}

.u-colorPurple {
  color: var(--brand-purple);
}

.Article-body a:is([href^="http"], [href^="mailto"], [href^="tel"]) {
  color: var(--brand-purple);
  font-weight: 600;
}

.Article-body ol,
.Article-body ul {
  margin: 20px 0 20px 40px;
}

.Article-body ol {
  list-style: decimal;
}

.Article-body ul {
  list-style: disc;
}

.Article-body li p {
  margin: 0px;
}

.Article-body h1,
.Article-body h2,
.Article-body h3 {
  margin: 20px 0;
  font-weight: 600;
}

.Article-body h1 a,
.Article-body h2 a,
.Article-body h3 a {
  font-weight: 600;
}

.Article-body h4,
.Article-body h5,
.Article-body h6 {
  margin: 5px 0;
  font-weight: 600;
}

.Article-body p {
  margin-bottom: 10px;
}

hr {
  margin: 20px 0;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  text-decoration: none;
}
